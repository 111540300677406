
import React from "react";
import "../App.css";

function App() {
  return (
    <div className="App">
      <div className="hero-section text-center bg-primary text-white py-5">
        <h3>Bringing Colors to Your World</h3>
        {/* <button className="btn btn-light mt-3" style={{width: '10%', display: 'flex', flex: 1, alignContent: 'center'}}>Learn More</button> */}
      </div>
      <div style={{
  background: '#007bff', // Blue background
  color: '#ffffff', // White text
  textAlign: 'center', // Center align text
  padding: '20px', // Padding around the content
  borderRadius: '5px', // Rounded corners
  margin: '20px 0', // Margin for top and bottom
}}>
  <h2>Get a free quote now!</h2>
  <h4>📞 047045311</h4>
</div>
      <div className="services-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">Our Services</h2>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 1"
                />
                <div className="card-body">
                  <h5 className="card-title">Interior Painting</h5>
                  <p className="card-text">
                    High-quality interior painting services to bring your walls
                    to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image2.jpg")}
                  className="card-img-top"
                  alt="Service 2"
                />
                <div className="card-body">
                  <h5 className="card-title">Exterior Painting</h5>
                  <p className="card-text">
                    Durable and weather-resistant painting solutions for
                    exteriors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image3.jpg")}
                  className="card-img-top"
                  alt="Service 3"
                />
                <div className="card-body">
                  <h5 className="card-title">Decorative Painting</h5>
                  <p className="card-text">
                    Custom decorative painting to add a unique touch to your
                    spaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* New services */}
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 4"
                />
                <div className="card-body">
                  <h5 className="card-title">Wallpaper Installation</h5>
                  <p className="card-text">
                    Professional wallpaper installation for an exquisite finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 5"
                />
                <div className="card-body">
                  <h5 className="card-title">Color Consultation</h5>
                  <p className="card-text">
                    Expert color consultation to match your unique style and
                    preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 6"
                />
                <div className="card-body">
                  <h5 className="card-title">Stucco Repair</h5>
                  <p className="card-text">
                    Comprehensive stucco repair services to restore your home's
                    beauty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section py-5 bg-light">
  <div className="container">
    <h2 className="text-center mb-4">About Us</h2>
    <p className="text-center">
      All Stars Painting, a proud sole trader business, has been setting the standard in the painting industry for over a decade. With a small, handpicked team of skilled professionals, we specialize in delivering high-quality painting services that not only meet but exceed your expectations.
    </p>
    <h4 className="text-center mt-4">Our Commitment</h4>
    <p className="text-center">
      At the heart of our business is a commitment to 100% customer satisfaction. We understand the importance of your space, and we treat every project with the utmost care and professionalism. Our approach is simple: combine traditional techniques with the latest technology to produce stunning, durable finishes.
    </p>
    <h4 className="text-center mt-4">Experience & Expertise</h4>
    <p className="text-center">
      With 10 years of experience under our belt, we have mastered the art of painting across a variety of settings. From residential homes to commercial spaces, our work speaks volumes of our dedication to quality and excellence. Our team is not just workers; they are craftsmen passionate about painting.
    </p>
  </div>
</div>
    </div>
  );
}

export default App;