import '../Header.css'; // Make sure to create this CSS file
import { Link  } from 'react-router-dom';

function Header() {
    return (
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>All Star Painting</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link"to={"/"}>Home <span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/NewService"}>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/gallery"}>Gallery</Link> {/* Updated this line */}
              </li>
              <li className="nav-item">
                <Link className="nav-link"to={'/Contact'}>Contact</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="mailto:yhaidari99@gmail.com">Email</a>
              </li>
            </ul>
          </div>
          <div class="search-icon">
            <a href="#"><i class="fas fa-search"></i></a>
          </div>
        </div>
      </nav>
    );
  }

  export default Header;