function NewService() {
  return (
    <div>
            <div className="services-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 1"
                />
                <div className="card-body">
                  <h5 className="card-title">Interior Painting</h5>
                  <p className="card-text">
                    High-quality interior painting services to bring your walls
                    to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image2.jpg")}
                  className="card-img-top"
                  alt="Service 2"
                />
                <div className="card-body">
                  <h5 className="card-title">Exterior Painting</h5>
                  <p className="card-text">
                    Durable and weather-resistant painting solutions for
                    exteriors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image3.jpg")}
                  className="card-img-top"
                  alt="Service 3"
                />
                <div className="card-body">
                  <h5 className="card-title">Decorative Painting</h5>
                  <p className="card-text">
                    Custom decorative painting to add a unique touch to your
                    spaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* New services */}
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 4"
                />
                <div className="card-body">
                  <h5 className="card-title">Wallpaper Installation</h5>
                  <p className="card-text">
                    Professional wallpaper installation for an exquisite finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 5"
                />
                <div className="card-body">
                  <h5 className="card-title">Color Consultation</h5>
                  <p className="card-text">
                    Expert color consultation to match your unique style and
                    preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={require("../images/image1.jpg")}
                  className="card-img-top"
                  alt="Service 6"
                />
                <div className="card-body">
                  <h5 className="card-title">Stucco Repair</h5>
                  <p className="card-text">
                    Comprehensive stucco repair services to restore your home's
                    beauty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewService;