import React from 'react';

function GridGallery() {
  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '30px',
    padding: '20px',
  };

  // Generate 30 items with varying properties
  const items = Array.from({ length: 30 }, (_, index) => ({
    id: index + 1,
    color: `hsl(${Math.random() * 360}, 70%, 80%)`, // Random light color
    size: (index % 5) + 1, // Varying size from 1 to 5
    image: require(`../images/image${(index % 3) + 1}.jpg`),
  }));

  return (
    <div style={gridContainerStyle}>
      {items.map(item => (
        <div
          key={item.id}
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: item.color,
            gridColumn: `span ${item.size}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
            minWidth: '200px',
            maxWidth: '90vw'
          }}
        >
          Item {item.id}
        </div>
      ))}
    </div>
  );
}

export default GridGallery;