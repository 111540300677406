


import React from "react";
import "./App.css";
import Header from "./Navigation/Header";
import Footer from "./Navigation/Footer";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import NewService from "./Pages/NewService";
import './firebase'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Imports...

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/Contact" element = {<Contact />} />
          <Route path="/NewService" element={<NewService />} />
          {/* Define other routes here */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
// function App() {
//   return (
//     <div className="App">
//       <Header />
//       <div className="hero-section text-center bg-primary text-white py-5">
//         <h3>Bringing Colors to Your World</h3>
//         {/* <button className="btn btn-light mt-3" style={{width: '10%', display: 'flex', flex: 1, alignContent: 'center'}}>Learn More</button> */}
//       </div>
//       <div className="services-section py-5">
//         <div className="container">
//           <h2 className="text-center mb-4">Our Services</h2>
//           <div className="row">
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image1.jpg")}
//                   className="card-img-top"
//                   alt="Service 1"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Interior Painting</h5>
//                   <p className="card-text">
//                     High-quality interior painting services to bring your walls
//                     to life.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image2.jpg")}
//                   className="card-img-top"
//                   alt="Service 2"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Exterior Painting</h5>
//                   <p className="card-text">
//                     Durable and weather-resistant painting solutions for
//                     exteriors.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image3.jpg")}
//                   className="card-img-top"
//                   alt="Service 3"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Decorative Painting</h5>
//                   <p className="card-text">
//                     Custom decorative painting to add a unique touch to your
//                     spaces.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             {/* New services */}
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image1.jpg")}
//                   className="card-img-top"
//                   alt="Service 4"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Wallpaper Installation</h5>
//                   <p className="card-text">
//                     Professional wallpaper installation for an exquisite finish.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image1.jpg")}
//                   className="card-img-top"
//                   alt="Service 5"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Color Consultation</h5>
//                   <p className="card-text">
//                     Expert color consultation to match your unique style and
//                     preferences.
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 mb-3">
//               <div className="card">
//                 <img
//                   src={require("./images/image1.jpg")}
//                   className="card-img-top"
//                   alt="Service 6"
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title">Stucco Repair</h5>
//                   <p className="card-text">
//                     Comprehensive stucco repair services to restore your home's
//                     beauty.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="about-section py-5 bg-light">
//         <div className="container">
//           <h2 className="text-center mb-4">About Us</h2>
//           <p className="text-center">
//             All Stars Painting has been providing top-notch painting services
//             for over a decade. Our team of skilled professionals is dedicated to
//             delivering exceptional results that exceed your expectations.
//           </p>
//         </div>
//       </div>
//       <div className="contact-section py-5">
//         <div className="container">
//           <h2 className="text-center mb-4">Contact Us</h2>
//           <form>
//             <div className="form-group">
//               <label for="name">Name</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="name"
//                 placeholder="Enter your name"
//               />
//             </div>
//             <div className="form-group">
//               <label for="email">Email</label>
//               <input
//                 type="email"
//                 className="form-control"
//                 id="email"
//                 placeholder="Enter your email"
//               />
//             </div>
//             <div className="form-group">
//               <label for="message">Message</label>
//               <textarea
//                 className="form-control"
//                 id="message"
//                 rows="3"
//               ></textarea>
//             </div>
//             <button type="submit" className="btn btn-primary">
//               Submit
//             </button>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default App;
