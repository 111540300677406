import React from 'react';
import '../Footer.css'; // Make sure to create this CSS file in the same directory

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
        <h5>Services</h5>
        <p>We provide comprehensive coverage across Melbourne, ensuring that no matter where you are, you can access our services. We offer a wide range of services tailored to meet the unique needs of our clients, from residential painting and decorating to commercial and industrial projects.</p>
        </div>
        <div className="footer-section">
          <h5>Contact Us</h5>
          <p>Free Qoute: Call 123456571</p>
          <p>Email: @paintingservices.com</p>
          <p>Phone: (123) 456-7890</p>
        </div>
        <div className="footer-section">
          <h4>ABN: <i>32 658 220 010</i></h4>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} ALL STARS PAINTING & BUILDING SERVICES PTY LTD.</p>
      </div>
    </footer>
  );
}


export default Footer;